import React, { useEffect, useState } from "react";
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalState,
} from "@web3modal/ethers5/react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getErrorMessage } from "../blockchainErrors";
import ConvertNumber from "../Helpers/ConvertNumber";

import Loader from "../Component/Loading";
import { maxPriorityFeePerGas } from "../config";
import {
  LoadBlockchainData,
  LoadPoolData,
  LoadUser,
} from "../Store/blockchainSlice";

function Home() {
  const dispatch = useDispatch();
  const {
    publicBlockchainData: data,
    user,
    isLoading,
    web3Inst_ETH,
    contractInstDrift_ETH,
    web3Inst_BNB,
    contractInstDrift_BNB,
    web3Inst_POLYGON,
    contractInstDrift_POLYGON,

    // Send Inst
    web3Inst_ETHSend,
    contractInstICO_ETHSend,
    contractInstPresaleToken_ETHSend,
    contractInstDrift_ETHSend,
    contractInstClaim_ETHSend,
    contractInstStakePool_ETHSend,
    contractInstDriftStake_ETHSend,

    web3Inst_BNBSend,
    contractInstICO_BNBSend,
    contractInstPresaleToken_BNBSend,
    contractInstDrift_BNBSend,
    contractInstClaim_BNBSend,
    contractInstStakePool_BNBSend,
    contractInstDriftStake_BNBSend,

    web3Inst_POLYGONSend,
    contractInstPresaleToken_POLYGONSend,
    contractInstICO_POLYGONSend,
    contractInstDrift_POLYGONSend,
    contractInstClaim_POLYGONSend,
    contractInstStakePool_POLYGONSend,
    contractInstDriftStake_POLYGONSend,

    contractInstBridge_ETHSend,
    contractInstBridge_BNBSend,
    contractInstBridge_POLYGONSend,
  } = useSelector((state) => state.Blockchain);

  const { address, chainId } = useWeb3ModalAccount();
  const { selectedNetworkId } = useWeb3ModalState();
  const { open } = useWeb3Modal();
  const [transactionModal, setTransactionModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [txHash, setTxHash] = useState("");
  const [errors, setErrors] = useState({
    transaction: "",
  });

  const GetCurrentInstance = () => {
    let Claim_TokenAddress;
    let PresaleToken_Inst;
    let ICO_Inst;
    let Claim_Inst;
    let Pool_Inst;
    let Stake_Drift_Inst;
    let Stake_Drift_Address;
    let Drift_Inst;
    let Pool_Address;
    let Web3_Inst;
    let Bridge_Address;
    let Bridge_Inst;
    let Drift_Address;
    let Drift_Token_Address;
    let Presale_TokenAddress;

    if (chainId === 1) {
      Web3_Inst = web3Inst_ETHSend;
      Claim_TokenAddress = process.env.REACT_APP_CLAIM_ETH;
      PresaleToken_Inst = contractInstPresaleToken_ETHSend;
      Presale_TokenAddress = process.env.REACT_APP_TOKEN_CONTRACT_ETH;
      ICO_Inst = contractInstICO_ETHSend;
      Claim_Inst = contractInstClaim_ETHSend;
      Pool_Inst = contractInstStakePool_ETHSend;
      Stake_Drift_Inst = contractInstDriftStake_ETHSend;
      Drift_Inst = contractInstDrift_ETHSend;
      Pool_Address = process.env.REACT_APP_ST_POOL_DRIFT_ETH;
      Stake_Drift_Address = process.env.REACT_APP_ST_DRIFT_ETH;
      Bridge_Address = process.env.REACT_APP_BRIDGE_ETH;
      Bridge_Inst = contractInstBridge_ETHSend;
      Drift_Address = process.env.REACT_APP_DRIFT_ETH;
      Drift_Token_Address = process.env.REACT_APP_DRIFT_ETH;
    } else if (chainId === 56) {
      Web3_Inst = web3Inst_BNBSend;
      Claim_TokenAddress = process.env.REACT_APP_CLAIM_BNB;
      PresaleToken_Inst = contractInstPresaleToken_BNBSend;
      Presale_TokenAddress = process.env.REACT_APP_TOKEN_CONTRACT_BNB;
      ICO_Inst = contractInstICO_BNBSend;
      Claim_Inst = contractInstClaim_BNBSend;
      Pool_Inst = contractInstStakePool_BNBSend;
      Stake_Drift_Inst = contractInstDriftStake_BNBSend;
      Drift_Inst = contractInstDrift_BNBSend;
      Pool_Address = process.env.REACT_APP_ST_POOL_DRIFT_BNB;
      Stake_Drift_Address = process.env.REACT_APP_ST_DRIFT_BNB;
      Bridge_Address = process.env.REACT_APP_BRIDGE_BNB;
      Bridge_Inst = contractInstBridge_BNBSend;
      Drift_Address = process.env.REACT_APP_DRIFT_BNB;
      Drift_Token_Address = process.env.REACT_APP_DRIFT_BNB;
    } else if (chainId === 137) {
      Web3_Inst = web3Inst_POLYGONSend;
      Claim_TokenAddress = process.env.REACT_APP_CLAIM_POLYGON;
      PresaleToken_Inst = contractInstPresaleToken_POLYGONSend;
      Presale_TokenAddress = process.env.REACT_APP_TOKEN_CONTRACT_POLYGON;
      ICO_Inst = contractInstICO_POLYGONSend;
      Claim_Inst = contractInstClaim_POLYGONSend;
      Pool_Inst = contractInstStakePool_POLYGONSend;
      Stake_Drift_Inst = contractInstDriftStake_POLYGONSend;
      Drift_Inst = contractInstDrift_POLYGONSend;
      Pool_Address = process.env.REACT_APP_ST_POOL_DRIFT_POLYGON;
      Stake_Drift_Address = process.env.REACT_APP_ST_DRIFT_POLYGON;
      Bridge_Address = process.env.REACT_APP_BRIDGE_POLYGON;
      Bridge_Inst = contractInstBridge_POLYGONSend;
      Drift_Address = process.env.REACT_APP_DRIFT_POLYGON;
      Drift_Token_Address = process.env.REACT_APP_DRIFT_POLYGON;
    }
    const res = {
      web3Inst: Web3_Inst,
      contractInstICO: ICO_Inst,
      address,
      contractInstPresaleToken: PresaleToken_Inst,
      contractInstClaim: Claim_Inst,
      claimAddress: Claim_TokenAddress,
      contractInstStakePool: Pool_Inst,
      contractInstDriftStake: Stake_Drift_Inst,
      contractInstDrift: Drift_Inst,
      pool_address: Pool_Address,
      bridge_address: Bridge_Address,
      Bridge_Inst,
      Drift_Address,
      Drift_Token_Address,
      Stake_Drift_Address,
      Presale_TokenAddress,
    };
    return res;
  };

  function scroll() {
    document
      .getElementById("Presale_Form")
      .scrollIntoView({ behavior: "smooth" }, true);
  }

  const allow = async (e) => {
    e.preventDefault();
    if (!address) {
      open();

      return;
    }
    setTransactionModal(true);
    setLoading(true);

    const {
      web3Inst,
      claimAddress,
      contractInstPresaleToken,
      contractInstICO,
      contractInstClaim,
      contractInstStakePool,
      contractInstDriftStake,
      contractInstDrift,
      pool_address,
      Stake_Drift_Address,
      Presale_TokenAddress,
    } = GetCurrentInstance();

    try {
      const approve = await contractInstPresaleToken.methods.approve(
        claimAddress,
        user?.balance + 0 || 0
      );

      const estimateGas = await approve.estimateGas({
        from: address,
      });

      const transaction = approve.send({
        from: address,
        to: Presale_TokenAddress,
        gas: estimateGas,
        maxPriorityFeePerGas,
      });

      transaction
        .on("transactionHash", (txHash) => {
          console.log(txHash);
          // setTxHash(txHash);
        })
        .on("receipt", async (receipt) => {
          console.log("RECEIPT => \n", receipt);

          setErrors((state) => ({ ...state, transaction: "" }));
          dispatch(
            LoadUser({
              address,
              web3Inst,
              claimAddress,
              contractInstPresaleToken,
              contractInstICO,
              contractInstClaim,
              contractInstStakePool,
              contractInstDriftStake,
              Stake_Drift_Address,
              contractInstDrift,
              pool_address,
            })
          );
          dispatch(
            LoadPoolData({
              web3Inst,
              contractInstStakePool,
            })
          );

          handleSubmit(e);
        })
        .on("error", async (error, receipt) => {
          console.log("ERROR => \n", error);

          setLoading(false);

          const errorMsg = await getErrorMessage(error, chainId);
          setErrors({ ...errors, transaction: errorMsg });
          console.log("RECEIPT ERROR => \n", receipt);

          if (receipt?.transactionHash) {
            setTxHash(receipt.transactionHash);
          }
        });
    } catch (error) {
      const errorMsg = await getErrorMessage(error, chainId);
      console.log(errorMsg);
      setErrors((state) => ({ ...state, transaction: errorMsg }));
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!address) {
      open();

      return;
    }
    setTransactionModal(true);
    setLoading(true);

    const {
      web3Inst,
      claimAddress,
      contractInstPresaleToken,
      contractInstICO,
      contractInstClaim,
      contractInstStakePool,
      contractInstDriftStake,
      contractInstDrift,
      pool_address,
    } = GetCurrentInstance();

    try {
      const claimTokens = await contractInstClaim.methods.claimTokens();
      const estimateGas = await claimTokens.estimateGas({
        from: address,
      });

      const transaction = claimTokens.send({
        from: address,
        to: claimAddress,
        gas: estimateGas,
        maxPriorityFeePerGas,
      });

      transaction
        .on("transactionHash", (txHash) => {
          console.log(txHash);
          setTxHash(txHash);
        })
        .on("receipt", async (receipt) => {
          console.log("RECEIPT => \n", receipt);

          setErrors((state) => ({ ...state, transaction: "" }));
          dispatch(
            LoadUser({
              web3Inst,
              contractInstICO,
              address,
              contractInstPresaleToken,
              contractInstClaim,
              claimAddress,
              contractInstStakePool,
              contractInstDriftStake,
              contractInstDrift,
              pool_address,
            })
          );
          dispatch(
            LoadPoolData({
              web3Inst,
              contractInstStakePool,
            })
          );

          dispatch(
            LoadBlockchainData({
              web3Inst_ETH,
              contractInstDrift_ETH,
              web3Inst_BNB,
              contractInstDrift_BNB,
              web3Inst_POLYGON,
              contractInstDrift_POLYGON,
            })
          );
          setLoading(false);
        })
        .on("error", async (error, receipt) => {
          console.log("ERROR => \n", error);

          setLoading(false);

          const errorMsg = await getErrorMessage(error, chainId);
          setErrors({ ...errors, transaction: errorMsg });
          console.log("RECEIPT ERROR => \n", receipt);

          if (receipt?.transactionHash) {
            setTxHash(receipt.transactionHash);
          }
        });
    } catch (error) {
      const errorMsg = await getErrorMessage(error, chainId);

      setErrors((state) => ({ ...state, transaction: errorMsg }));
      setLoading(false);
    }
  };

  const closeTransactionModal = () => {
    setTransactionModal(false);

    setErrors({
      transaction: "",
    });
    setTxHash("");
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="Home">
      <div className="HomeHeroSection d-flex align-items-center position-relative overflow-hidden">
        <div className="container-lg">
          <section className=" d-flex justify-content-between flex-column align-items-center ">
            <div className="row m-0 w-100 Home_Hero_Section d-flex justify-content-center text-center ">
              <div className="col-12 col-md-8 p-0 ">
                <p className="Home_Hero_Section_SubHeading fw-bold text-uppercase mb-0">
                  A TOKEN BUILT DIFFERENTLY
                </p>
                <h1 className="Home_Hero_Section_Heading mt-2 fw-bold text-white text-uppercase">
                  A Bold New Approach
                </h1>

                <p className=" text-white m-0 p-0 my-4">
                  A Web3 project like no other
                  <span className="mx-1 " style={{ color: "#FF98D1" }}>
                    |
                  </span>
                  A token for speculators
                  <span className="mx-1 " style={{ color: "#FF98D1" }}>
                    |
                  </span>
                  A cross chain game for competitive gamers
                  <span className="mx-1 " style={{ color: "#FF98D1" }}>
                    |
                  </span>
                  A platform for influencers to earn
                  <span className="mx-1 " style={{ color: "#FF98D1" }}>
                    |
                  </span>
                  A whole new approach to tokenomics
                </p>

                <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                  <Link
                    to={
                      "https://www.dextools.io/app/en/ether/pair-explorer/0xa6d783d1f832f983f64c4272ef7f4cc5c4fa4f1e?t=1719942898171"
                    }
                    className="pinkBtn BtnStyle1 d-flex align-items-center gap-2 text-white"
                    style={{ height: "40px" }}
                    target="_blank"
                  >
                    View Live Chart on Dextools
                    <img
                      src="/image/DEXTools_White.png"
                      className=""
                      style={{ width: "20px" }}
                      alt=""
                    />
                  </Link>
                  <Link
                    to={
                      "https://app.uniswap.org/swap?inputCurrency=ETH&outputCurrency=0xb7cFfebB06621287C7850ffefB22c30252E78e6B"
                    }
                    className="pinkBtn BtnStyle1 d-flex align-items-center gap-2 text-white"
                    style={{ height: "40px" }}
                    target="_blank"
                  >
                    Buy $DRIFT token on Uniswap
                    <svg
                      fill="white"
                      className=""
                      style={{ width: "20px" }}
                      viewBox="0 0 14 15"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M4.152 1.551c-.188-.029-.196-.032-.107-.045.17-.026.57.009.846.074.644.152 1.23.542 1.856 1.235l.166.184.238-.038c1.002-.16 2.02-.033 2.873.358.235.108.605.322.65.377.016.018.043.13.06.251.064.418.033.737-.096.976-.07.13-.074.171-.027.283a.274.274 0 0 0 .246.154c.212 0 .44-.34.545-.814l.042-.189.083.094c.457.514.815 1.214.876 1.712l.016.13-.076-.118a1.462 1.462 0 0 0-.435-.453c-.306-.201-.63-.27-1.486-.315-.774-.04-1.212-.106-1.646-.247-.739-.24-1.111-.558-1.989-1.702-.39-.509-.63-.79-.87-1.016-.545-.515-1.08-.785-1.765-.89Z"></path>
                      <path d="M10.85 2.686c.019-.34.065-.565.159-.77a.825.825 0 0 1 .077-.148c.005 0-.011.06-.036.133-.068.2-.08.472-.032.789.06.402.093.46.52.894.201.204.434.46.519.571l.154.2-.154-.143c-.188-.175-.62-.517-.716-.566-.064-.032-.074-.032-.113.007-.037.036-.044.09-.05.346-.007.399-.062.655-.194.91-.071.14-.082.11-.018-.047.048-.116.053-.168.053-.554 0-.775-.094-.962-.637-1.28a5.971 5.971 0 0 0-.504-.26 1.912 1.912 0 0 1-.246-.12c.015-.015.545.139.758.22.318.122.37.137.409.123.025-.01.038-.085.05-.305ZM4.517 4.013c-.381-.522-.618-1.323-.566-1.922l.015-.185.087.015c.164.03.445.134.577.214.361.218.518.505.677 1.243.047.216.108.46.136.544.045.133.217.444.356.646.1.146.034.215-.188.195-.339-.03-.798-.345-1.094-.75ZM10.386 7.9c-1.784-.713-2.412-1.333-2.412-2.378 0-.154.005-.28.012-.28.006 0 .075.05.153.113.362.288.767.411 1.889.574.66.096 1.03.173 1.373.286 1.09.359 1.763 1.087 1.924 2.08.046.288.02.828-.057 1.113-.06.225-.242.63-.29.646-.014.005-.027-.046-.03-.116-.018-.372-.208-.735-.526-1.007-.362-.309-.848-.555-2.036-1.03ZM9.134 8.197a3.133 3.133 0 0 0-.086-.375l-.046-.135.085.095c.117.13.21.297.288.52.06.17.066.22.066.496 0 .271-.008.328-.064.48a1.518 1.518 0 0 1-.376.596c-.326.33-.745.512-1.35.588-.105.013-.411.035-.68.049-.679.035-1.126.108-1.527.248a.324.324 0 0 1-.115.027c-.016-.016.258-.178.483-.286.318-.153.635-.236 1.345-.353.35-.058.713-.129.805-.157.868-.264 1.315-.947 1.172-1.793Z"></path>
                      <path d="M9.952 9.641c-.237-.506-.292-.995-.162-1.451.014-.05.036-.089.05-.089.013 0 .07.03.124.067.11.073.328.196.912.512.728.395 1.144.7 1.426 1.05.247.305.4.654.474 1.078.042.24.017.82-.045 1.062-.196.764-.65 1.364-1.3 1.714-.095.051-.18.093-.19.093-.009 0 .026-.087.077-.194.219-.454.244-.895.079-1.386-.102-.301-.308-.668-.724-1.289-.484-.72-.602-.913-.721-1.167ZM3.25 12.374c.663-.556 1.486-.95 2.237-1.072a3.51 3.51 0 0 1 1.161.045c.48.122.91.396 1.133.721.218.319.312.596.41 1.214.038.243.08.488.092.543.073.32.216.576.392.704.28.204.764.217 1.239.033a.618.618 0 0 1 .155-.048c.017.017-.222.176-.39.26a1.334 1.334 0 0 1-.648.156c-.435 0-.796-.22-1.098-.668a5.3 5.3 0 0 1-.296-.588c-.318-.721-.475-.94-.844-1.181-.322-.21-.737-.247-1.049-.095-.41.2-.524.72-.23 1.05a.911.911 0 0 0 .512.266.545.545 0 0 0 .619-.544c0-.217-.084-.34-.295-.436-.289-.129-.598.022-.597.291 0 .115.051.187.167.24.074.033.076.035.015.023-.264-.055-.326-.372-.114-.582.256-.252.784-.141.965.204.076.145.085.433.019.607-.15.39-.582.595-1.022.483-.3-.076-.421-.158-.782-.527-.627-.642-.87-.767-1.774-.907l-.174-.027.197-.165Z"></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M.308.884C2.402 3.41 3.845 4.452 4.005 4.672c.132.182.082.346-.144.474a1.381 1.381 0 0 1-.515.143c-.147 0-.198-.056-.198-.056-.085-.08-.133-.066-.57-.837A132.96 132.96 0 0 0 1.45 2.67c-.032-.03-.031-.03 1.067 1.923.177.407.035.556.035.614 0 .118-.033.18-.179.343-.244.27-.353.574-.432 1.203-.088.705-.336 1.203-1.024 2.056-.402.499-.468.59-.57.792-.128.253-.163.395-.177.714-.015.339.014.557.118.88.09.284.186.47.429.844.21.323.33.563.33.657 0 .074.014.074.34.001.776-.174 1.407-.48 1.762-.857.22-.233.271-.361.273-.68.001-.208-.006-.252-.063-.372-.092-.195-.26-.358-.63-.61-.486-.33-.694-.595-.75-.96-.048-.3.007-.511.275-1.07.278-.58.347-.827.394-1.41.03-.377.071-.526.18-.646.114-.124.216-.166.498-.204.459-.063.75-.18.99-.4a.853.853 0 0 0 .31-.652l.01-.21-.117-.134C4.098 4.004.026.5 0 .5-.005.5.133.673.308.884Zm.976 9.815a.37.37 0 0 0-.115-.489c-.15-.1-.385-.052-.385.077 0 .04.022.069.072.094.084.043.09.091.024.19-.067.099-.061.186.015.246.123.095.297.043.389-.118ZM4.925 5.999c-.215.065-.424.292-.49.53-.039.145-.016.4.043.478.096.127.188.16.439.159.49-.003.916-.212.966-.474.04-.214-.147-.51-.405-.641a.965.965 0 0 0-.553-.052Zm.574.445c.075-.107.042-.222-.087-.3-.244-.149-.615-.026-.615.204 0 .115.193.24.37.24.118 0 .28-.07.332-.144Z"
                      ></path>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      {transactionModal && (
        <div
          className="TransactionModal h-100 w-100 position-fixed top-0  d-flex justify-content-center align-items-center"
          style={{ background: "rgba(0,0,0,0.5)", zIndex: 999 }}
        >
          <div
            className="TransactionModalBox DTSC_Col bg-white rounded-4 p-4 position-relative"
            style={{ wordWrap: "break-word" }}
          >
            {loading ? (
              <div className="d-flex justify-content-center align-items-center">
                <img
                  src="/image/loading.gif"
                  style={{ width: 50 }}
                  alt="loading"
                />
              </div>
            ) : (
              <>
                <img
                  alt=""
                  src="/image/Close.svg"
                  className="position-sticky top-0  start-100"
                  style={{ width: 20 }}
                  onClick={closeTransactionModal}
                />
                {errors?.transaction ? (
                  <div className="d-flex flex-column align-items-center">
                    <div
                      className="p-3 mb-3"
                      style={{
                        borderRadius: "100%",
                        backgroundColor: "#ff008c",
                      }}
                    >
                      <img
                        alt=""
                        src="/image/Error.svg"
                        style={{ width: 30, height: 30 }}
                      />
                    </div>
                    <p> {errors?.transaction}</p>
                  </div>
                ) : (
                  <>
                    <div className="d-flex justify-content-center mb-3">
                      <p className="border p-2 rounded-4 text-center text-white bg-black m-0 px-4">
                        Transaction Successful !
                      </p>
                    </div>
                    <p className="text-start text-black">
                      You have successfully Claimed $DRIFT tokens!
                    </p>

                    <p className="text-start text-black ">
                      <span className="fw-bold">Import contract :</span>{" "}
                      {selectedNetworkId === 1
                        ? process.env.REACT_APP_DRIFT_ETH
                        : selectedNetworkId === 56
                        ? process.env.REACT_APP_DRIFT_BNB
                        : selectedNetworkId === 137
                        ? process.env.REACT_APP_DRIFT_POLYGON
                        : null}{" "}
                      to view your tokens.{" "}
                    </p>
                    <p className="text-start text-black ">
                      <span className="fw-bold">Import Stake contract :</span>{" "}
                      {selectedNetworkId === 1
                        ? process.env.REACT_APP_ST_DRIFT_ETH
                        : selectedNetworkId === 56
                        ? process.env.REACT_APP_ST_DRIFT_BNB
                        : selectedNetworkId === 137
                        ? process.env.REACT_APP_ST_DRIFT_POLYGON
                        : null}{" "}
                      to view your stake tokens.{" "}
                    </p>
                    <p className="text-start text-black mb-0 fw-bold">
                      Transaction link:
                    </p>
                    <Link
                      target="_blank"
                      to={`${
                        selectedNetworkId === 1
                          ? "https://etherscan.io"
                          : `${
                              selectedNetworkId === 56
                                ? "https://bscscan.com"
                                : `${
                                    selectedNetworkId === 137
                                      ? "https://polygonscan.com"
                                      : ""
                                  }`
                            }`
                      }/tx/${txHash}`}
                    >
                      {selectedNetworkId === 1
                        ? "https://etherscan.io"
                        : `${
                            selectedNetworkId === 56
                              ? "https://bscscan.com"
                              : `${
                                  selectedNetworkId === 137
                                    ? "https://polygonscan.com"
                                    : ""
                                }`
                          }`}
                      /tx/{txHash}
                    </Link>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}

      <div className="container-lg">
        <section className="DriftTokenSection pt-5">
          <div className="DriftTokenSectionContainer row m-0 w-100 align-items-stretch">
            <div className="col-12 col-md-6 p-0 pe-md-3">
              <div className="m-0 p-0">
                <video className="w-100 rounded-4" autoPlay muted loop>
                  <source src="/PayoutPersuit.mp4" type="video/mp4"></source>
                </video>
              </div>
            </div>
            <div className="col-12 col-md-6 p-0 ps-md-3 pt-3 pt-md-0 d-flex justify-content-center align-items-center">
              <div className="DTSC_Col Play_Game bg-transparent shadow-none py-0">
                <h2 className="DTSC_Heading text-black mb-0 text-uppercase">
                  A Ready-To-Play Game
                </h2>
                <div className="my-3">
                  <p>
                    Payout Pursuit is a groundbreaking Web3 skills-based racing
                    game that is played on browser.
                  </p>
                  <p>
                    The V1 (Beta Version) was ready-to-play, requiring no
                    downloads and no wallet connection.
                  </p>
                  <p>
                    V2 brings Web3 integration, allowing racers to race against
                    each other for crypto prizes with cross chain connectivity
                    to expand the player universe.
                  </p>

                  <p className="m-0">
                    Start honing your skills now! version 2 is unveiled!
                  </p>
                </div>
                <Link
                  className={`FooterMenuContainer_Link`}
                  to={"https://play.payoutpursuit.com/"}
                  target="_blank"
                >
                  <button className="white BtnStyle1 shadow-none">
                    Play V2
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="DriftTokenSection pt-3 pt-md-5">
          <div className="DriftTokenSectionContainer row m-0 w-100 align-items-stretch position-relative ">
            <div
              className=" col-12 col-md-6 p-0 pe-md-3 rounded-4 position-relative"
              id="Presale_Form"
            >
              {isLoading ? (
                <div
                  className="w-100 h-100 bg-white  rounded-4 d-flex justify-content-center align-items-center py-5"
                  style={{ zIndex: 9 }}
                >
                  <Loader />
                </div>
              ) : (
                <div
                  className="DTSC_Col rounded-4 bg-white mt-4 mt-md-0 position-relative  "
                  id="claim"
                >
                  <h2 className="DTSC_Heading text-black mb-0 text-uppercase ">
                    Claim $DRIFT Token
                  </h2>
                  <div className="row m-0 mt-3 w-100">
                    <div className="d-flex align-items-start p-0">
                      <img
                        alt=""
                        src="/image/Dot.svg"
                        style={{ marginRight: 10 }}
                      />
                      <p className="m-0">
                        Total Remaining Claims{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {data?.tokensToClaim || 0}
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="mt-5">
                    <div className="NetworkButtons_Row row m-0 w-100">
                      <p className="p-0">Select Network</p>
                      <div className="col-12 col-xl-4 col-lg-6 col-sm-6  p-0 pe-lg-2 pe-sm-2 mb-2 mb-xl-0">
                        <button
                          onClick={() => open({ view: "Networks" })}
                          className={`BtnStyle2 ${
                            selectedNetworkId === 1 && chainId === 1
                              ? "active"
                              : ""
                          }`}
                        >
                          <img
                            alt=""
                            src="/image/Ethereum.svg"
                            className="me-1"
                          />
                          ETH
                        </button>
                      </div>
                      <div className="col-12 col-xl-4 col-lg-6 col-sm-6 p-0 pe-xl-2 mb-2 mb-xl-0">
                        <button
                          onClick={() => open({ view: "Networks" })}
                          className={`BtnStyle2 ${
                            selectedNetworkId === 56 && chainId === 56
                              ? "active"
                              : ""
                          }`}
                        >
                          <img alt="" src="/image/BNB.svg" className="me-1" />
                          BNB
                        </button>
                      </div>
                      <div className="col-12 col-xl-4 col-lg-6 col-sm-6 p-0">
                        <button
                          onClick={() => open({ view: "Networks" })}
                          className={`BtnStyle2 ${
                            selectedNetworkId === 137 && chainId === 137
                              ? "active"
                              : ""
                          }`}
                        >
                          <img
                            alt=""
                            src="/image/Polygon.svg"
                            className="me-1"
                          />
                          Polygon
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="mt-5 position-relative">
                    {user?.balance !== 0 ? (
                      <form className="position-relative">
                        <div className="row p-0 m-0 ">
                          <div className="col-12 p-0">
                            <label>
                              <p>PreDrift Tokens</p>
                            </label>
                            <div className="input align-items-center">
                              <input
                                readOnly
                                className="input-field"
                                type="number"
                                name="no_of_tokens"
                                id="no_of_tokens"
                                value={
                                  ConvertNumber(Number(user?.balance), true) ||
                                  0
                                }
                                style={{ marginRight: 15 }}
                              />
                              <img
                                src="/image/Drift_Icon.svg"
                                alt=""
                                width={32}
                                height={32}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row p-0 m-0 mt-3">
                          {/* <div className="col-12 col-xl-6 ps-0 pe-0 pe-xl-2">
                            <label>
                              <p>Staked Tokens</p>
                            </label>
                            <div className="input align-items-center">
                              <input
                                readOnly
                                className="input-field"
                                type="number"
                                name="no_of_tokens"
                                id="no_of_tokens"
                                value={user?.Staked || 0}
                                style={{ marginRight: 15 }}
                              />
                              <img src="/image/Drift_Icon.svg" alt="" width={32} height={32} />
                            </div>
                          </div> */}
                          <div
                            className="
                      col-12 mt-4 mt-xl-0 pe-0 ps-0"
                          >
                            <label>
                              <p>Drift Tokens</p>
                            </label>
                            <div className="input align-items-center">
                              <input
                                readOnly
                                className="input-field"
                                type="number"
                                name="no_of_tokens"
                                id="no_of_tokens"
                                // value={user?.Dynamic || 0}
                                value={
                                  ConvertNumber(Number(user?.balance), true) ||
                                  0
                                }
                                style={{ marginRight: 15 }}
                              />

                              <img
                                src="/image/Drift_Icon.svg"
                                alt=""
                                width={32}
                                height={32}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="d-flex mt-5 flex-column align-items-baseline justify-content-between flex-xxl-row align-items-xxl-center">
                          <div className="mb-3 mb-xxl-0">
                            {user?.is_allowed ? (
                              <button
                                className="btn pinkBtn  BtnStyle1 text-white"
                                onClick={handleSubmit}
                                disabled={user?.balance === undefined}
                              >
                                Claim $Drift
                              </button>
                            ) : (
                              <button
                                className="btn pinkBtn  BtnStyle1 text-white"
                                disabled={user?.balance === undefined}
                                onClick={allow}
                              >
                                Claim $Drift
                              </button>
                            )}
                          </div>
                          <Link
                            to="/utilities"
                            style={{ fontWeight: 800 }}
                            className="text-uppercase"
                          >
                            Learn About Coin Utility
                          </Link>
                        </div>
                      </form>
                    ) : (
                      <div
                        style={{ height: 264 }}
                        className="white rounded-4  p-3 d-flex align-items-center justify-content-center"
                      >
                        {user?.claimed && user?.balance === 0 ? (
                          <div className="d-flex flex-column justify-content-center align-items-center">
                            <img
                              src="/image/Drift Logo Spin.svg"
                              height={100}
                              alt="drift_logo"
                            />
                            <p className="m-0 p-0 mt-3 text-center  align-self-center">
                              🎉{" "}
                              <span className="Home_Hero_Section_SubHeading fw-bold text-uppercase text-black">
                                Congratulations!
                              </span>
                              <br />
                              You've successfully claimed your Drift Token!{" "}
                            </p>
                          </div>
                        ) : (
                          <div>
                            <p className="m-0 p-0 mt-3 text-center  fw-bold">
                              No Presale DRIFT tokens found in connected wallet.
                            </p>
                            <p className="m-0 p-0 mt-3 text-center  ">
                              Unable to claim tokens at the moment.
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="col-12 col-md-6 p-0 ps-md-3 pt-3 pt-md-0 ">
              <div className="p-0 h-100">
                <div className="DTSC_Col rounded-4 bg-white h-100 d-flex flex-column justify-content-center align-items-center">
                  <h2 className="DTSC_Heading text-black  mb-0 text-uppercase">
                    Presale Concluded!
                  </h2>
                  <p className="mt-3 text-center">
                    Claim your $DRIFT tokens now before the official launch!
                    <br />
                    Don't miss out on this opportunity to secure your stake.
                  </p>
                  <div>
                    <img
                      src="/image/PresaleEnded.png"
                      className="img-fluid"
                      alt="drifting_car"
                    />
                  </div>
                </div>
              </div>
              {/* <div className="py-3">
                <div className="DTSC_Col rounded-4 bg-white">
                  <p className="DTSC_SubHeading mb-0 text-uppercase fw-bold text-black">
                  Ambassador Payout to date
                  </p>
                  <h2 className="DTSC_Heading text-black mb-0">
                   ${(data?.ambassadors_payout * ethPrice || 0).toFixed()}
                  
              <div className="p-0">
                <div className="DTSC_Col rounded-4 bg-white">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="DTSC_SubHeading mb-0 text-uppercase fw-bold text-black">
                      Top Ambassadors
                    </p>

                    <img src={subtract} />
                  </div>

                  
                </div>
              </div> */}
            </div>
          </div>
        </section>

        <section className="TeamSection mt-5">
          <div className="TeamContainer">
            <h1 className="Home_Hero_Section_Heading text-uppercase mb-0 text-black">
              A Strong team
            </h1>
            <div className="mt-5 d-flex w-100 justify-content-between flex-column flex-lg-row">
              <div className="TeamSection_Col rounded-4 bg-white  d-flex flex-column align-items-center">
                <img
                  alt=""
                  src="/image/calendar-icon.svg"
                  width={41}
                  height={50}
                />
                <h2 className="TeamSection_CounterNumber  mb-2 text-center text-uppercase">
                  50 Years
                </h2>
                <p className="TeamSection_CounterName mb-0 text-center">
                  Combined Web3 Experience
                </p>
              </div>
              <div className="TeamSection_Col rounded-4 bg-white  d-flex flex-column align-items-center mx-0 mx-lg-5 my-3 my-lg-0">
                <img
                  alt=""
                  src="/image/money-icon.svg"
                  width={41}
                  height={50}
                />
                <h2 className="TeamSection_CounterNumber mb-2  text-center text-uppercase">
                  50 Million
                </h2>
                <p className="TeamSection_CounterName mb-0  text-center">
                  Dollars Raised
                </p>
              </div>
              <div className="TeamSection_Col rounded-4 bg-white  d-flex flex-column align-items-center">
                <img alt="" src="/image/gold-icon.svg" width={41} height={50} />
                <h2 className="TeamSection_CounterNumber mb-2   text-center text-uppercase">
                  800 MILLION+
                </h2>
                <p className="TeamSection_CounterName mb-0   text-center">
                  Valuation of Projects Team Has Worked For
                </p>
              </div>
            </div>
            <div className="TeamMember_Row rounded-4 bg-transparent shadow-none mt-5">
              <div className="TeamMember row d-flex justify-content-around">
                <div className="TeamMember_Profile col-12 d-flex flex-column text-center align-items-center col-lg-3 col-sm-6">
                  <div className="TeamMember_Profile_Container bg-white p-3 rounded-4">
                    <img
                      alt=""
                      className="TeamMemberProfile_Picture mb-4"
                      src="/image/Josh.png"
                    ></img>
                    <p className="TeamMemberProfile_Name text-black text-capitalize mb-1">
                      Joshua Chand
                    </p>
                    <p className="TeamMemberProfile_Position mb-3">
                      Head of Operations / Interim CEO
                    </p>
                    <p className="TeamMemberProfile_Bio">
                      Joshua Chand is a experienced professional at the helm of
                      operations and interim leadership, leveraging a wealth of
                      experience across a diverse spectrum of Web3 projects. As
                      Head of Operations and interim CEO, he embodies a
                      strategic blend of operational expertise and visionary
                      leadership, steering organizations through the dynamic
                      landscapes of decentralized technologies.
                    </p>
                    <div className="TeamMemberProfile_Socials mt-auto">
                      <Link
                        to={"https://twitter.com/TheDriftToken"}
                        target="_blank"
                      >
                        <img
                          alt=""
                          src="/image/Twitter_Icon.png"
                          className="SocialIcon me-2"
                        />
                      </Link>
                      <Link
                        to={"https://www.linkedin.com/in/josh-chand"}
                        target="_blank"
                      >
                        <img
                          alt=""
                          src="/image/LinkedIn_Icon.png"
                          className="SocialIcon"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="TeamMember_Profile col-12 d-flex flex-column text-center  align-items-center col-lg-3 col-sm-6 mt-5 mt-sm-0">
                  <div className="TeamMember_Profile_Container bg-white p-3 rounded-4">
                    <img
                      alt=""
                      className="TeamMemberProfile_Picture mb-4"
                      src="/image/Paul.png"
                    ></img>
                    <p className="TeamMemberProfile_Name text-black mb-1">
                      Paul de Havilland
                    </p>
                    <p className="TeamMemberProfile_Position mb-3">
                      Head of Web3 Marketing
                    </p>
                    <p className="TeamMemberProfile_Bio">
                      Paul de Havilland is a experienced marketing strategist
                      with a prolific track record in the dynamic landscapes of
                      NFTs, GameFi, the metaverse, and various platforms within
                      the Web3 space. As Head of Web3 Marketing, Paul brings a
                      wealth of experience and a history of successes in
                      crafting and executing innovative marketing campaigns that
                      resonate with diverse audiences.
                    </p>
                    <div className="TeamMemberProfile_Socials mt-auto">
                      <Link
                        to={"https://twitter.com/pauliedehav"}
                        target="_blank"
                      >
                        <img
                          alt=""
                          src="/image/Twitter_Icon.png"
                          className="SocialIcon me-2 "
                        />
                      </Link>
                      <Link
                        to={"https://www.linkedin.com/in/pauldehavilland/"}
                        target="_blank"
                      >
                        <img
                          alt=""
                          src="/image/LinkedIn_Icon.png"
                          className="SocialIcon"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="TeamMember_Profile col-12 d-flex flex-column  text-center align-items-center col-lg-3 col-sm-6 mt-5 mt-lg-0">
                  <div className="TeamMember_Profile_Container bg-white p-3 rounded-4">
                    <img
                      alt=""
                      className="TeamMemberProfile_Picture mb-4"
                      src="/image/Michael.png"
                    ></img>
                    <p className="TeamMemberProfile_Name text-black text-capitalize mb-1">
                      Michael Tabone
                    </p>
                    <p className="TeamMemberProfile_Position mb-3">
                      Head of Strategy
                    </p>
                    <p className="TeamMemberProfile_Bio">
                      As a strategic visionary, Mike has played a pivotal role
                      in shaping the narrative of blockchain technology. His
                      tenure at Cointelegraph, a leading source of news and
                      insights in the crypto space, has equipped him with a deep
                      understanding of the industry's nuances and an acute
                      awareness of emerging trends.
                    </p>
                    <div className="TeamMemberProfile_Socials mt-auto">
                      <Link to={"https://twitter.com/EconDAO"} target="_blank">
                        <img
                          alt=""
                          src="/image/Twitter_Icon.png"
                          className="SocialIcon me-2 "
                        />
                      </Link>
                      <Link
                        to={"https://www.linkedin.com/in/michaeltabone/"}
                        target="_blank"
                      >
                        <img
                          alt=""
                          src="/image/LinkedIn_Icon.png"
                          className="SocialIcon"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="TeamMember_Profile col-12 d-flex flex-column  text-center align-items-center col-lg-3 col-sm-6 mt-5 mt-lg-0">
                  <div className="TeamMember_Profile_Container bg-white p-3 rounded-4">
                    <img
                      alt=""
                      className="TeamMemberProfile_Picture mb-4"
                      src="/image/Sophie.png"
                    ></img>
                    <p className="TeamMemberProfile_Name text-black text-capitalize mb-1">
                      Sophie Hendry
                    </p>
                    <p className="TeamMemberProfile_Position mb-3">
                      Head of Design
                    </p>
                    <p className="TeamMemberProfile_Bio">
                      Sophie Hendry stands at the forefront of design innovation
                      in the Web3 landscape, bringing a wealth of experience and
                      creative acumen to her role as Head of Design. With a
                      diverse background spanning various Web3 entities,
                      including DAOs, the metaverse, NFTs, and platforms, Sophie
                      is a trailblazer in crafting immersive and visually
                      compelling digital experiences.
                    </p>
                    <div className="TeamMemberProfile_Socials mt-auto">
                      <Link
                        to={"https://twitter.com/spinkick_eth"}
                        target="_blank"
                      >
                        <img
                          alt=""
                          src="/image/Twitter_Icon.png"
                          className="SocialIcon me-2 "
                        />
                      </Link>
                      <Link
                        to={"https://www.linkedin.com/in/sophiehendry/"}
                        target="_blank"
                      >
                        <img
                          alt=""
                          src="/image/LinkedIn_Icon.png"
                          className="SocialIcon"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="RoadmapSection mt-5 d-flex">
        <div className="container-lg" style={{ display: "block" }}>
          <h1 className="Home_Hero_Section_Heading text-uppercase mb-0 text-white">
            Road Map
          </h1>
          <div className="Roadmap_Row  rounded-4  bg-black mt-5">
            <h1 className="Roadmap_Row_Heading text-white mb-0 text-uppercase">
              Presale
            </h1>
            <div className="PresaleContainer mt-3 mt-sm-5 row m-0 w-100">
              <div className="col-12 ">
                <div
                  className="Presale_Col rounded-4 text-white h-100"
                  style={{ background: "rgba(51, 50, 50, 0.6)" }}
                >
                  <p className="Presale_Col_Heading text-white text-uppercase fw-bold">
                    Check List
                  </p>
                  <div className="row">
                    <div className="col-12 col-lg-6">
                      <ul className="list-group mb-0 p-0">
                        <li className="list-group-item p-0 border-0 mb-1 d-flex align-items-start bg-transparent">
                          <img
                            alt=""
                            src="/image/marked.png"
                            className="me-2"
                          />
                          Website 2.0 Live
                        </li>
                        <li className="list-group-item p-0 border-0 mb-1 d-flex align-items-start bg-transparent">
                          <img
                            alt=""
                            src="/image/marked.png"
                            className="me-2"
                          />
                          V1 Game Playable
                        </li>
                        <li className="list-group-item p-0 border-0 mb-1 d-flex align-items-start bg-transparent">
                          <img
                            alt=""
                            src="/image/marked.png"
                            className="me-2"
                          />
                          First 10 Gold Ambassadors on-boarded
                        </li>

                        <li className="list-group-item p-0 border-0 mb-1 d-flex align-items-start bg-transparent">
                          <img
                            alt=""
                            src="/image/marked.png"
                            className="me-2"
                          />
                          Telegram Open
                        </li>
                        <li className="list-group-item p-0 border-0 mb-1 d-flex align-items-start bg-transparent">
                          <img
                            alt=""
                            src="/image/marked.png"
                            className="me-2"
                          />
                          Organize Twitter AMAs (Spaces)
                        </li>
                        <li className="list-group-item p-0 border-0 mb-1 d-flex align-items-start bg-transparent">
                          <img
                            alt=""
                            src="/image/marked.png"
                            className="me-2"
                          />
                          Presale Warm Up Lap opens ($250K)
                        </li>
                        <li className="list-group-item p-0 border-0 mb-1 d-flex align-items-start bg-transparent">
                          <img
                            alt=""
                            src="/image/marked.png"
                            className="me-2"
                          />
                          Ambassador Program expansion
                        </li>
                        <li className="list-group-item p-0 border-0 mb-0 d-flex align-items-start bg-transparent">
                          <img
                            alt=""
                            src="/image/marked.png"
                            className="me-2"
                          />
                          Presale Warm Up Lap complete
                        </li>
                        <li className="list-group-item p-0 border-0 mb-1 d-flex align-items-start bg-transparent">
                          <img
                            alt=""
                            src="/image/marked.png"
                            className="me-2"
                          />
                          Top 3 Ambassadors Presale Warmup Lap Awarded ($4.5k
                          total)
                        </li>
                        <li className="list-group-item p-0 border-0 mb-1 d-flex align-items-start bg-transparent">
                          <img
                            alt=""
                            src="/image/marked.png"
                            className="me-2"
                          />
                          Presale Lap 1 opens ($500K)
                        </li>
                        <li className="list-group-item p-0 border-0 mb-1 d-flex align-items-start bg-transparent">
                          <img
                            alt=""
                            src="/image/marked.png"
                            className="me-2"
                          />
                          Crypto publication press coverage (Cointelegraph,
                          additional coverage in various crypto media outlets)
                        </li>
                        <li className="list-group-item p-0 border-0 mb-0 d-flex align-items-start bg-transparent">
                          <img
                            alt=""
                            src="/image/marked.png"
                            className="me-2"
                          />
                          Presale Lap 1 complete
                        </li>
                        <li className="list-group-item p-0 border-0 mb-1 d-flex align-items-start bg-transparent">
                          <img
                            alt=""
                            src="/image/marked.png"
                            className="me-2"
                          />
                          Top 3 Ambassadors Presale Lap 1 Awarded ($15k total)
                        </li>
                        <li className="list-group-item p-0 border-0 mb-1 d-flex align-items-start bg-transparent">
                          <img
                            alt=""
                            src="/image/marked.png"
                            className="me-2"
                          />
                          Presale Final Lap opens ($750K)
                        </li>
                        <li className="list-group-item p-0 border-0 mb-1 d-flex align-items-start bg-transparent">
                          <img
                            alt=""
                            src="/image/marked.png"
                            className="me-2"
                          />
                          Begin Web3 ad campaigns (Twitter,Youtube, Twitch,
                          TikTok, Reddit)
                        </li>
                        <li className="list-group-item p-0 border-0 mb-1 d-flex align-items-start bg-transparent">
                          <img
                            alt=""
                            src="/image/marked.png"
                            className="me-2"
                          />
                          Twitter, Youtube, Twitch GameFi ad campaign planning
                        </li>
                        <li className="list-group-item p-0 border-0 mb-1 d-flex align-items-start bg-transparent">
                          <img
                            alt=""
                            src="/image/marked.png"
                            className="me-2"
                          />
                          Open & staff Discord
                        </li>
                      </ul>
                    </div>
                    <div className="col-12 col-lg-6 customStartBorder ">
                      <ul className="list-group mb-0 p-0">
                        <li className="list-group-item p-0 border-0 mb-1 d-flex align-items-start bg-transparent">
                          <img
                            alt=""
                            src="/image/unmarked.png"
                            className="me-2"
                          />
                          Web3 campaign planning (Twitter, Youtube, TikTok,
                          Reddit)
                        </li>
                        <li className="list-group-item p-0 border-0 mb-1 d-flex align-items-start bg-transparent">
                          <img
                            alt=""
                            src="/image/unmarked.png"
                            className="me-2"
                          />
                          Top 3 Ambassadors Presale Final Lap Awarded ($22.5k
                          total)
                        </li>

                        <li className="list-group-item p-0 border-0 mb-1 d-flex align-items-start bg-transparent">
                          <img
                            alt=""
                            src="/image/unmarked.png"
                            className="me-2"
                          />
                          Begin GameFi Marketing (KOL-driven, Youtube, Twitter,
                          Twitch, Chinese social media KOLs)
                        </li>

                        <li className="list-group-item p-0 border-0 mb-1 d-flex align-items-start bg-transparent">
                          <img
                            alt=""
                            src="/image/unmarked.png"
                            className="me-2"
                          />
                          Broad press outreach
                        </li>
                        <li className="list-group-item p-0 border-0 mb-1 d-flex align-items-start bg-transparent">
                          <img
                            alt=""
                            src="/image/unmarked.png"
                            className="me-2"
                          />
                          Continued Twitter, Youtube, Twitch, TikTok, Reddit
                          Web3 and GameFi campaigns
                        </li>

                        <li className="list-group-item p-0 border-0 mb-1 d-flex align-items-start bg-transparent">
                          <img
                            alt=""
                            src="/image/unmarked.png"
                            className="me-2"
                          />
                          China-market expansion in GameFi segment through
                          Chinese social media KOLs
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Roadmap_Row  rounded-4  bg-black mt-5">
            <h1 className="Roadmap_Row_Heading text-white mb-0 text-uppercase">
              Launch
            </h1>
            <div className="PresaleContainer mt-3 mt-sm-5 row m-0 w-100">
              <div className="col-12 ">
                <div
                  className="Presale_Col rounded-4 text-white"
                  style={{ background: "rgba(51, 50, 50, 0.6)" }}
                >
                  <p className="Presale_Col_Heading text-white text-uppercase fw-bold m-0">
                    Coming Soon
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="TokenomicsSection">
        <div className="TokenomicsContainer">
          <div className="container-lg">
            <h1 className="Home_Hero_Section_Heading text-uppercase">
              Tokenomics
            </h1>
            <div>
              <div className="d-flex justify-content-between mt-5 flex-column flex-lg-row">
                <div className="Tokenomics_Col rounded-4 me-0 bg-white py-3 w-100 me-lg-5 mb-5 mb-lg-0 d-flex flex-column justify-content-between ">
                  <div className=" d-flex align-items-center justify-content-start text-center py-3 px-3 px-sm-5">
                    <h1 className="Tokenomics_Col_Heading text-black mb-0 text-start text-uppercase">
                      Token Supply Allocation Breakdown
                    </h1>
                  </div>
                  <div
                    className="d-flex  align-items-center justify-content-between
                    flex-column flex-sm-row px-3 px-sm-5 my-3
                  "
                  >
                    <img src="/image/AllocationBreakdown.png" alt="" />

                    <ul className="list-group mt-4 mt-sm-0">
                      <li className="list-group-item p-0 border-0 mb-1 d-flex align-items-start">
                        <img
                          src="/image/Presale.svg"
                          className="me-3"
                          alt="Presale"
                        />
                        <div>
                          <p className="m-0 b-0 lh-1"> Presale</p>
                          <span>57%</span>
                        </div>
                      </li>
                      <li className="list-group-item p-0 border-0 mb-1 d-flex align-items-start">
                        <img alt="LP" src="/image/Lp.svg" className="me-3" />
                        <div>
                          <p className="m-0 b-0 lh-1"> LP</p>
                          <span>21%</span>
                        </div>
                      </li>
                      <li className="list-group-item p-0 border-0 mb-1 d-flex align-items-start">
                        <img
                          alt="Reserve Tank"
                          src="/image/ReserveTank.svg"
                          className="me-3"
                        />
                        <div>
                          <p className="m-0 b-0 lh-1"> Reserve Tank</p>
                          <span>13%</span>
                        </div>
                      </li>
                      <li className="list-group-item p-0 border-0 mb-1 d-flex align-items-start">
                        <img
                          src="/image/Burn.svg"
                          className="me-3"
                          alt="Burn"
                        />
                        <div>
                          <p className="m-0 b-0 lh-1"> Burn</p>
                          <span>5%</span>
                        </div>
                      </li>
                      <li className="list-group-item p-0 border-0 d-flex align-items-start">
                        <img
                          src="/image/Ambassador.svg"
                          className="me-3"
                          alt="Ambassador"
                        />
                        <div>
                          <p className="m-0 b-0 lh-1">Ambassador</p>
                          <span>4%</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="Tokenomics_Col rounded-4 w-100 bg-white py-3 d-flex flex-column justify-content-around">
                  <div className=" d-flex align-items-center justify-content-start text-center py-3 px-3 px-sm-5">
                    <h1 className="Tokenomics_Col_Heading text-black mb-0 text-uppercase">
                      Tax Breakdown
                    </h1>
                  </div>
                  <div className="">
                    <div className="BreakdownRow  d-flex justify-content-between border-top px-3 px-sm-5">
                      <div className="Bar  w-75 me-0  bg-white d-flex justify-content-start align-items-center ">
                        <p className="Breakdowwn_Heading mb-0">
                          Liquidity Reversion
                        </p>
                      </div>
                      <div className="Bar bg-white  w-25 d-flex justify-content-end align-items-center border-start ">
                        <p className="mb-0">1%</p>
                      </div>
                    </div>
                    <div className="BreakdownRow  d-flex justify-content-between border-top px-3 px-sm-5">
                      <div className="Bar  w-75 me-0  bg-white d-flex justify-content-start align-items-center ">
                        <p className="Breakdowwn_Heading mb-0">Revenue Share</p>
                      </div>
                      <div className="Bar bg-white  w-25 d-flex justify-content-end align-items-center border-start ">
                        <p className="mb-0">1%</p>
                      </div>
                    </div>
                    <div className="BreakdownRow  d-flex justify-content-between border-top px-3 px-sm-5">
                      <div className="Bar  w-75 me-0  bg-white d-flex justify-content-start align-items-center ">
                        <p className="Breakdowwn_Heading mb-0">Game Pool</p>
                      </div>
                      <div className="Bar bg-white  w-25 d-flex justify-content-end align-items-center border-start ">
                        <p className="mb-0">3%</p>
                      </div>
                    </div>

                    <div className="BreakdownRow  d-flex justify-content-between border-top px-3 px-sm-5">
                      <div
                        className="Bar  w-75 me-0  bg-white d-flex justify-content-start align-items-center "
                        style={{
                          borderRadius: "0px 0px 0px 21px",
                        }}
                      >
                        <p className="Breakdowwn_Heading mb-0">Total</p>
                      </div>
                      <div
                        className="Bar bg-white  w-25 d-flex justify-content-end align-items-center border-start "
                        style={{
                          borderRadius: "0px 0px 21px 0px",
                        }}
                      >
                        <p className="mb-0">5%</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-0 m-0 mt-5 d-flex justify-content-center">
                <div className=" col-12 col-xl-8  Tokenomics_Col rounded-4 me-0 py-3 bg-white  d-flex flex-column justify-content-between">
                  <div className=" d-flex align-items-center justify-content-start text-center py-3 px-3 px-sm-5">
                    <h1 className="Tokenomics_Col_Heading text-black mb-0 text-uppercase">
                      Presale Use of Funds
                    </h1>
                  </div>
                  <div
                    className="d-flex  align-items-center justify-content-between
                    flex-column flex-sm-row px-3 px-sm-5 my-3
                    
                  "
                  >
                    <img src="/image/PresaleUseofFunds.png" alt="" />
                    <ul className="list-group mt-4 mt-sm-0">
                      <li className="list-group-item p-0 border-0 mb-1 d-flex align-items-start">
                        <img
                          src="/image/Presale.svg"
                          className="me-3"
                          alt="Initiation LP"
                        />

                        <div>
                          <p className="m-0 b-0 lh-1"> Initiation LP</p>
                          <span>44%</span>
                        </div>
                      </li>
                      <li className="list-group-item p-0 border-0 mb-1 d-flex align-items-start">
                        <img
                          alt="Marketing"
                          src="/image/Lp.svg"
                          className="me-3"
                        />
                        <div>
                          <p className="m-0 b-0 lh-1 "> Marketing</p>
                          <span>28%</span>
                        </div>
                      </li>
                      <li className="list-group-item p-0 border-0 mb-1 d-flex align-items-start">
                        <img
                          alt="Development"
                          src="/image/ReserveTank.svg"
                          className="me-3"
                        />
                        <div>
                          <p className="m-0 b-0 lh-1"> Development</p>
                          <span>17%</span>
                        </div>
                      </li>
                      <li className="list-group-item p-0 border-0 mb-1 d-flex align-items-start">
                        <img
                          src="/image/Burn.svg"
                          className="me-3"
                          alt="Listings/Partnerships"
                        />

                        <div>
                          <p className="m-0 b-0 lh-1">Listings/Partnerships</p>
                          <span>11%</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
