const NoPage = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center bg-black"
      style={{ height: "100vh" }}
    >
      <h1 className="type-animation">404</h1>
    </div>
  );
};

export default NoPage;
